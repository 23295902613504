import { Fragment } from "react";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import "./ProgressIndicator.scss";

interface IProgressIndicator {
  steps: string[];
  currentStep: number;
  className?: string;
}

/** A non-interactive component that tracks progress through a wizard.
 *  Uses simple colored lines to indicate step progress.
 */
export const ProgressIndicator = (props: IProgressIndicator) => {
  const progressSteps = props.steps.map((step: string, index: number) => {
    const stepNumber = index + 1;

    return (
      <Fragment key={step}>
        <Col className="progress-step">
          <div
            className={classnames("step-indicator", {
              "active-step": props.currentStep >= stepNumber,
              "inactive-step": props.currentStep < stepNumber,
            })}
          />
        </Col>
      </Fragment>
    );
  });

  return (
    <Row className={`p-0 progress-indicator ${props.className}`}>
      {progressSteps}
    </Row>
  );
};
