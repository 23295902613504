import { useContext, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { CDataButton, ButtonType } from "src/components/buttons/CDataButton";
import { SelectDataSourcesCard } from "./SelectDataSourcesCard";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { CDataRow } from "src/components/layout/CDataRow";
import { CDataCol } from "src/components/layout/CDataCol";
import { SelectClientToolsCard } from "./SelectClientToolsCard";
import { IconSprite } from "../../../../clientConnectivity/IconSprite";

interface SelectDataSourcesAndToolsProps
  extends Partial<StepWizardChildProps> {}

export const SelectDataSourcesAndTools = (
  props: SelectDataSourcesAndToolsProps,
) => {
  const surveyContext = useContext(SurveyWizardContext);
  const [isNoDataSourcesMatchChecked, setIsNoDataSourcesMatchChecked] =
    useState(false);
  const [isNoClientToolsMatchChecked, setIsNoClientToolsMatchChecked] =
    useState(false);

  const areDataSourcesUnselected =
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    (!isNoDataSourcesMatchChecked ||
      !surveyContext.surveyForm.unsupportedDriver);

  const areClientToolsUnselected =
    surveyContext.surveyForm.selectedClientTools.length === 0 &&
    (!isNoClientToolsMatchChecked ||
      !surveyContext.surveyForm.unsupportedClientTool);

  const isNextDisabled = areDataSourcesUnselected || areClientToolsUnselected;

  const onSubmit = async () => {
    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyContext.surveyForm),
    });

    props.nextStep!();
  };

  return (
    <div className="select-data-sources-and-tools">
      <CDataRow className="select-sources-and-tools-row">
        <CDataCol className="d-flex justify-content-end">
          <SelectDataSourcesCard
            isNoDataSourcesMatchChecked={isNoDataSourcesMatchChecked}
            setIsNoDataSourcesMatchChecked={setIsNoDataSourcesMatchChecked}
          />
        </CDataCol>
        <CDataCol className="arrow-col">
          <i className="fa-regular fa-arrow-right arrow-icon" />
        </CDataCol>
        <CDataCol>
          <SelectClientToolsCard
            isNoClientToolsMatchChecked={isNoClientToolsMatchChecked}
            setIsNoClientToolsMatchChecked={setIsNoClientToolsMatchChecked}
          />
        </CDataCol>
      </CDataRow>
      <CDataButton
        onClick={() => onSubmit()} // Passed in from the step wizard
        buttonType={ButtonType.Primary}
        disabled={isNextDisabled}
        className="next-button mb-3"
      >
        Next
      </CDataButton>
      <IconSprite />
    </div>
  );
};
