import { useContext } from "react";
import { driverCategories } from "src/components/drivers/DriverUtilities";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataTypography } from "src/components/text/CDataTypography";
import { NoResultsFound } from "./NoResultsFound";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { PopularProducts } from "./PopularProducts";
import { ClientDetails } from "src/pages/clientConnectivity/ClientDetails";
import { useGridFilter } from "src/components/tables/GridTable/useGridFilter";
import { GridTable, GridTile } from "src/components/tables/GridTable/GridTable";
import { SimpleTile } from "src/components/tables/GridTable/SimpleTile";
import { popularClientToolsNames } from "./popularClientToolsNames";
import { ToastrSuccess } from "src/services/toastrService";
import { UnsupportedClientTool } from "./UnsupportedClientTool";
import * as clientToolImages from "src/pages/clientConnectivity/images";
import { ClientToolsImageLoader } from "../../../../clientConnectivity/ClientToolsImageLoader";

type SelectClientToolsCardProps = {
  isNoClientToolsMatchChecked: boolean;
  setIsNoClientToolsMatchChecked: (
    isNoClientToolsMatchChecked: boolean,
  ) => void;
};

export function SelectClientToolsCard(props: SelectClientToolsCardProps) {
  const { isNoClientToolsMatchChecked, setIsNoClientToolsMatchChecked } = props;

  const surveyContext = useContext(SurveyWizardContext);

  const clientToolCards = createClientToolCards();

  const gridFilter = useGridFilter<GridTile>({
    tiles: clientToolCards,
    categories: driverCategories,
  });

  function createClientToolCards() {
    const newClientToolCards: GridTile[] = ClientDetails.map((client) => {
      const ImageComponent =
        clientToolImages[client.imageIcon as keyof typeof clientToolImages];
      const icon = <ImageComponent />;

      return {
        name: client.testId!,
        tile: (
          <SimpleTile
            name={client.description!}
            icon={icon}
            isSelected={surveyContext.surveyForm.selectedClientTools.includes(
              client.testId!,
            )}
            onClick={() => onClickClientTool(client.testId!)}
          />
        ),
      };
    });

    return newClientToolCards;
  }

  const popularClientTools = ClientDetails.filter((d) =>
    popularClientToolsNames.includes(d.testId!),
  ).sort(
    (a, b) =>
      popularClientToolsNames.indexOf(a.testId!) -
      popularClientToolsNames.indexOf(b.testId!),
  );

  const popularProducts = popularClientTools.map((clientTool) => {
    return {
      key: clientTool.testId!,
      title: clientTool.description!,
      isSelected: surveyContext.surveyForm.selectedClientTools.includes(
        clientTool.testId!,
      ),
      img: (
        <ClientToolsImageLoader
          imageIcon={clientTool.imageIcon}
          className="client-tool-avatar card-img-top img-thumbnail"
        />
      ),
    };
  });

  const addClientTool = (clientToolName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedClientTools = [
        ...newSurveyForm.selectedClientTools,
        clientToolName,
      ];
      return newSurveyForm;
    });
  };

  const removeClientTool = (clientToolName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedClientTools = [
        ...newSurveyForm.selectedClientTools,
      ].filter((clientTool) => clientTool !== clientToolName);
      return newSurveyForm;
    });
  };

  function onClickClientTool(clientTool: string | undefined) {
    if (clientTool) {
      if (!surveyContext.surveyForm.selectedClientTools.includes(clientTool)) {
        addClientTool(clientTool);
      } else {
        removeClientTool(clientTool);
      }
    }
  }

  const setUnsupportedClientTool = (clientTool: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.unsupportedClientTool = clientTool;
      return newSurveyForm;
    });
  };

  const updateUnsupportedClientTool = async (clientTool: string) => {
    let newClientTools = "";
    if (!surveyContext.surveyForm.unsupportedClientTool) {
      newClientTools = clientTool;
    } else {
      newClientTools = `${surveyContext.surveyForm.unsupportedClientTool}, ${clientTool}`;
    }

    const surveyForm = { ...surveyContext.surveyForm };
    surveyForm.unsupportedClientTool = newClientTools;

    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyForm),
    });

    ToastrSuccess(
      "Successfully submitted",
      "The data consumer you’re looking for has been successfully submitted.",
    );

    setUnsupportedClientTool(newClientTools);
  };

  let adjustedHeightClass = undefined;
  if (
    surveyContext.surveyForm.selectedClientTools.length === 0 &&
    !isNoClientToolsMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-1";
  } else if (
    surveyContext.surveyForm.selectedClientTools.length === 0 &&
    isNoClientToolsMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-2";
  }

  return (
    <CDataCard className="select-client-tools-card selection-card">
      <CDataTypography
        className="required mb-3"
        variant="typography-variant-card-title"
      >
        How do you plan to consume your data? (Select all that apply)
      </CDataTypography>
      <PopularProducts
        productsTitle="Popular Consumers:"
        products={popularProducts}
        onClickProduct={onClickClientTool}
      />
      <GridTable
        tiles={clientToolCards}
        emptyElement={
          <NoResultsFound
            updateUnsupportedProduct={updateUnsupportedClientTool}
            productType="Data Consumer"
          />
        }
        className={adjustedHeightClass}
        gridFilter={gridFilter}
      />
      {surveyContext.surveyForm.selectedClientTools.length === 0 &&
        gridFilter.filteredTiles.length !== 0 && (
          <UnsupportedClientTool
            noProductsMatchChecked={isNoClientToolsMatchChecked}
            setNoProductsMatchChecked={setIsNoClientToolsMatchChecked}
            setUnsupportedProduct={setUnsupportedClientTool}
          />
        )}
    </CDataCard>
  );
}
