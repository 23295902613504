import { useContext, useEffect, useState } from "react";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { CDataCheckbox } from "src/components/CDataCheckbox";
import { CDataTextField } from "src/components/form/CDataTextField";
import classnames from "classnames";
import { useDebounce } from "react-use";

interface UnsupportedDataSourceProps {
  noProductsMatchChecked: boolean;
  setNoProductsMatchChecked: (noProductsMatchChecked: boolean) => void;
  setUnsupportedProduct: (product: string) => void;
}

export function UnsupportedDataSource(props: UnsupportedDataSourceProps) {
  const {
    noProductsMatchChecked,
    setNoProductsMatchChecked,
    setUnsupportedProduct,
  } = props;

  const surveyContext = useContext(SurveyWizardContext);

  const [product, setProduct] = useState(
    surveyContext.surveyForm.unsupportedDriver,
  );

  useDebounce(
    () => {
      setUnsupportedProduct(product ?? "");
    },
    100,
    [product],
  );

  useEffect(() => {
    setProduct(surveyContext.surveyForm.unsupportedDriver);
  }, [surveyContext.surveyForm.unsupportedDriver]);

  return (
    <CDataCard className="unsupported-product">
      <CDataCardBody className="d-flex flex-column p-3">
        <div
          className={classnames("d-flex", {
            "mb-2": noProductsMatchChecked,
          })}
        >
          <CDataCheckbox
            checked={noProductsMatchChecked}
            onChange={() => setNoProductsMatchChecked(!noProductsMatchChecked)}
            className="ps-0 pe-2 py-0"
          />
          <CDataTypography
            variant="typography-variant-body-medium"
            className="text-start align-content-center"
          >
            None of the data sources provided here match my use case.
          </CDataTypography>
        </div>
        {noProductsMatchChecked && (
          <CDataTextField
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            placeholder={`What data source are you looking for?*`}
            inputBaseClassName="unsupported-data-source-text-field"
          />
        )}
      </CDataCardBody>
    </CDataCard>
  );
}
