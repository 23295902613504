import { Fragment, useContext } from "react";
import classnames from "classnames";
import "./Slider.scss";

import { Button, Col, Row } from "reactstrap";

import { IModalProps } from "../CDataModal";
import { ModalContext } from "../../routes/ModalContext";

interface ISliderProps {
  steps: string[];
  preventNavigation?: boolean;
  preventNavigationTitle?: string;
  preventNavigationMessage?: string;
  updatePreventNavigation: (
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) => void;
  restrictUnvisitedSteps?: boolean;
  completedSteps: boolean[];
  confirmationStep?: boolean;
  initialStep?: string;
  currentStep?: number;
  goToStep?: (stepNumber: number) => void;
  totalSteps?: number;
  className?: string;
}

const Slider = (props: ISliderProps) => {
  const modalContext = useContext(ModalContext);

  const getComponentName = () => {
    return "components-Slider";
  };

  const goToStepValidated = (stepNumber: number) => {
    if (props.currentStep === stepNumber) {
      return;
    }

    if (props.completedSteps[stepNumber - 1] && props.preventNavigation) {
      const modal = {
        title: props.preventNavigationTitle,
        body: <text>{props.preventNavigationMessage}</text>,
        secondaryButton: (
          <Button color="dark" onClick={() => modalContext.toggleModal()}>
            Cancel
          </Button>
        ),
        primaryButton: (
          <Button
            color="danger"
            onClick={() => {
              modalContext.toggleModal();
              props.updatePreventNavigation(false, "", "");
              props.goToStep!(stepNumber);
            }}
          >
            Leave Page
          </Button>
        ),
        displayToggleCloseButton: true,
        displayed: true,
        modalSize: "lg",
      } as IModalProps;
      modalContext.setModal(modal);
    } else {
      if (
        !props.restrictUnvisitedSteps ||
        (props.restrictUnvisitedSteps && props.completedSteps[stepNumber - 1])
      ) {
        props.goToStep!(stepNumber);
      }
    }
  };

  const sliderSteps = props.steps.map((step: string, index: number) => {
    if (index === props.steps.length - 1) {
      return false;
    }
    const stepNumber = index + 1;
    const divider =
      index !== props.steps.length - 2 ? (
        <Col className="divider">
          <hr />
        </Col>
      ) : null;

    const innerText = props.completedSteps[stepNumber] ? (
      <i className="fa fa-check step-completed-check align-middle no-pointer-event" />
    ) : (
      stepNumber
    );

    return (
      <Fragment key={step}>
        <Col className="step text-center">
          <Button
            onClick={() => goToStepValidated(stepNumber)}
            className={classnames("slider-button", {
              visited:
                props.currentStep === stepNumber ||
                props.completedSteps[stepNumber],
            })}
            data-testid={`test-step-${stepNumber}`}
          >
            {innerText}
          </Button>
          <div
            className={classnames(
              "ms-2 d-inline fw-bold text-nowrap slider-step-title",
              {
                "step-title-active": props.currentStep === stepNumber,
                "step-title-visited": props.completedSteps[stepNumber],
              },
            )}
          >
            {props.steps[index]}
          </div>
        </Col>
        {divider}
      </Fragment>
    );
  });

  return (
    <Row className={`p-0 ${getComponentName()} ${props.className}`}>
      {sliderSteps}
    </Row>
  );
};

export default Slider;
