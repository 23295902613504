import { useContext } from "react";
import { useAppSelector } from "src/redux/hooks";
import {
  compareDrivers,
  driverCategories,
} from "src/components/drivers/DriverUtilities";
import { GridTable, GridTile } from "src/components/tables/GridTable/GridTable";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataTypography } from "src/components/text/CDataTypography";
import { NoResultsFound } from "./NoResultsFound";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { UnsupportedDataSource } from "./UnsupportedDataSource";
import { useGridFilter } from "src/components/tables/GridTable/useGridFilter";
import { PopularProducts } from "./PopularProducts";
import { popularSourcesNames } from "./popularSourcesNames";
import { getBaseIconUrl } from "src/components/drivers/DriverIconFactory";
import { SimpleTile } from "src/components/tables/GridTable/SimpleTile";
import { DriverIcon } from "src/components/drivers/DriverIcon";
import { ToastrSuccess } from "src/services/toastrService";

type SelectDataSourcesCardProps = {
  isNoDataSourcesMatchChecked: boolean;
  setIsNoDataSourcesMatchChecked: (
    isNoDataSourcesMatchChecked: boolean,
  ) => void;
};

export function SelectDataSourcesCard(props: SelectDataSourcesCardProps) {
  const { isNoDataSourcesMatchChecked, setIsNoDataSourcesMatchChecked } = props;

  const surveyContext = useContext(SurveyWizardContext);
  const driversList = useAppSelector((state) => state.driversList);

  const drivers = getDrivers();

  const dataSourceCards = createDataSourceCards();

  const gridFilter = useGridFilter<GridTile>({
    tiles: dataSourceCards,
    categories: driverCategories,
  });

  function getDrivers() {
    const mappedDrivers = driversList.drivers!.map((driver) => driver);
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);

    return visibleDrivers.sort(compareDrivers);
  }

  function createDataSourceCards() {
    if (drivers.length > 0) {
      const newDataSourceCards: GridTile[] = drivers.map((driver) => {
        return {
          name: driver.niceName ?? "",
          category: driver.category ?? "",
          tile: (
            <SimpleTile
              name={driver.niceName ?? ""}
              onClick={() => onClickDataSource(driver.driver)}
              isSelected={surveyContext.surveyForm.selectedDrivers.includes(
                driver.driver!,
              )}
              icon={<DriverIcon driver={driver.driver!} />}
            />
          ),
        };
      });

      return newDataSourceCards;
    }

    return [];
  }

  const popularSources = drivers
    .filter((d) => popularSourcesNames.includes(d.driver!))
    .sort(
      (a, b) =>
        popularSourcesNames.indexOf(a.driver!) -
        popularSourcesNames.indexOf(b.driver!),
    );

  const popularProducts = popularSources.map((driver) => {
    return {
      key: driver.driver!,
      title: driver.niceName!,
      isSelected: surveyContext.surveyForm.selectedDrivers.includes(
        driver.driver!,
      ),
      img: (
        <img
          src={`${getBaseIconUrl()}/${driver.driver}.svg`}
          className="card-img-top img-thumbnail"
          alt={`${driver.driver} icon`}
        />
      ),
    };
  });

  const addDriver = (driverName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedDrivers = [
        ...newSurveyForm.selectedDrivers,
        driverName,
      ];
      return newSurveyForm;
    });
  };

  const removeDriver = (driverName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedDrivers = [...newSurveyForm.selectedDrivers].filter(
        (dataSource) => dataSource !== driverName,
      );
      return newSurveyForm;
    });
  };

  function onClickDataSource(driverName: string | undefined) {
    if (driverName) {
      if (!surveyContext.surveyForm.selectedDrivers.includes(driverName)) {
        addDriver(driverName);
      } else {
        removeDriver(driverName);
      }
    }
  }

  const setUnsupportedDriver = (driver: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.unsupportedDriver = driver;
      return newSurveyForm;
    });
  };

  const updateUnsupportedDataSource = async (dataSource: string) => {
    let newDataSources = "";
    if (!surveyContext.surveyForm.unsupportedDriver) {
      newDataSources = dataSource;
    } else {
      newDataSources = `${surveyContext.surveyForm.unsupportedDriver}, ${dataSource}`;
    }

    const surveyForm = { ...surveyContext.surveyForm };
    surveyForm.unsupportedDriver = newDataSources;

    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyForm),
    });

    ToastrSuccess(
      "Successfully submitted",
      "The data source you’re looking for has been successfully submitted.",
    );

    setUnsupportedDriver(newDataSources);
  };

  let adjustedHeightClass = undefined;
  if (
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    !isNoDataSourcesMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-1";
  } else if (
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    isNoDataSourcesMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-2";
  }

  return (
    <CDataCard className="select-data-sources-card selection-card">
      <CDataTypography
        className="required mb-3"
        variant="typography-variant-card-title"
      >
        What sources do you need access to? (Select all that apply)
      </CDataTypography>
      <PopularProducts
        productsTitle="Popular Sources:"
        products={popularProducts}
        onClickProduct={onClickDataSource}
      />
      <GridTable
        categories={driverCategories}
        tiles={dataSourceCards}
        emptyElement={
          <NoResultsFound
            updateUnsupportedProduct={updateUnsupportedDataSource}
            productType="Data Source"
          />
        }
        className={adjustedHeightClass}
        gridFilter={gridFilter}
      />
      {surveyContext.surveyForm.selectedDrivers.length === 0 &&
        gridFilter.filteredTiles.length !== 0 && (
          <UnsupportedDataSource
            noProductsMatchChecked={isNoDataSourcesMatchChecked}
            setNoProductsMatchChecked={setIsNoDataSourcesMatchChecked}
            setUnsupportedProduct={setUnsupportedDriver}
          />
        )}
    </CDataCard>
  );
}
