import { useState } from "react";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTextField } from "src/components/form/CDataTextField";
import { CDataTypography } from "src/components/text/CDataTypography";
import ApiConnectorLogo from "src/assets/img/Rest.svg?react";

interface NoResultsFoundProps {
  updateUnsupportedProduct: (product: string) => void;
  productType: string;
}

export function NoResultsFound(props: NoResultsFoundProps) {
  const { updateUnsupportedProduct, productType } = props;
  const [product, setProduct] = useState<string>("");

  const onSubmit = () => {
    updateUnsupportedProduct(product);
    setProduct("");
  };

  return (
    <div className="no-results-found text-center">
      <CDataTypography
        variant="typography-variant-headline-4"
        color="typography-color-text-muted"
        className="mb-3"
      >
        No results found.
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="mb-1"
      >
        Can’t find the {productType} you’re looking for?
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="mb-3"
      >
        Let us know by entering it below!
      </CDataTypography>
      <CDataTextField
        value={product}
        onChange={(e) => setProduct(e.target.value)}
        className="mb-2"
        inputBaseClassName="data-source-text-field"
      />
      <CDataButton
        buttonType={ButtonType.Primary}
        className="mb-3"
        onClick={onSubmit}
        disabled={!product}
      >
        Submit
      </CDataButton>
      {productType === "Data Source" && (
        <CDataCard className="api-connector-card">
          <CDataCardBody className="d-flex">
            <div className="api-connector-icon-wrapper me-3">
              <ApiConnectorLogo
                className="api-connector-icon"
                aria-description="API Connector"
              />
            </div>
            <CDataTypography
              variant="typography-variant-body-medium"
              color="typography-color-medium-grey"
              className="text-start align-content-center"
            >
              You can also try our API Connector for seamless integration with
              third-party applications.
            </CDataTypography>
          </CDataCardBody>
        </CDataCard>
      )}
    </div>
  );
}
