import { useState } from "react";
import StepWizard, { StepWizardProps } from "react-step-wizard";
import { ProgressIndicator } from "src/components/progressindicator/ProgressIndicator";
import { AboutUser } from "./Steps/1-AboutUser/AboutUser";
import { SignupReasonSelector } from "./Steps/3-SignupReasons/SignupReasonSelector";
import { SurveyWizardContext } from "./SurveyWizardContext";
import { CDataTypography } from "src/components/text/CDataTypography";
import { SelectDataSourcesAndTools } from "./Steps/2-SelectDataSources/SelectDataSourcesAndTools";
import { IUserSignupReason } from "src/models/Accounts/IUserSignupReason";
import { useMutation } from "@tanstack/react-query";
import { setSignupReasons } from "./setSignupReasons";
import { Spinner } from "reactstrap";

export type SurveyForm = {
  department: string;
  role: string;
  dataProficiency: string;
  selectedDrivers: string[];
  unsupportedDriver?: string;
  selectedClientTools: string[];
  unsupportedClientTool?: string;
  signupReason: string[];
  signupReasonsOther?: string;
};

interface ISurveyWizard {
  existingSignupReasons: IUserSignupReason;
  setSurveyCompleted: (completed: boolean) => void;
}

export const SurveyWizard = (props: ISurveyWizard) => {
  const { existingSignupReasons, setSurveyCompleted } = props;

  const {
    mutateAsync: setSignupReasonsAsync,
    isPending: isSetSignUpReasonsPending,
  } = useMutation({
    mutationKey: ["/signupreasons/set"],
    mutationFn: setSignupReasons,
    meta: {
      errorMessage: "Failed to set signup reasons due to the following error:",
    },
  });

  let initialSurveyForm: SurveyForm;
  let initialStep = 1;
  if (existingSignupReasons.surveyForm) {
    initialSurveyForm = JSON.parse(existingSignupReasons.surveyForm);

    const isStep1Completed =
      initialSurveyForm.department &&
      initialSurveyForm.role &&
      initialSurveyForm.dataProficiency;
    const isStep2Completed =
      (initialSurveyForm.selectedDrivers.length > 0 ||
        initialSurveyForm.unsupportedDriver) &&
      (initialSurveyForm.selectedClientTools.length > 0 ||
        initialSurveyForm.unsupportedClientTool);

    if (isStep1Completed && !isStep2Completed) {
      initialStep = 2;
    } else if (isStep1Completed && isStep2Completed) {
      initialStep = 3;
    }
  } else {
    initialSurveyForm = {
      department: "",
      role: "",
      dataProficiency: "",
      selectedDrivers: [],
      selectedClientTools: [],
      signupReason: [],
    };
  }

  const [surveyForm, setSurveyForm] = useState<SurveyForm>(initialSurveyForm);
  const [currentStep, setCurrentStep] = useState(initialStep);

  const steps = [
    "aboutUser",
    "connectionInterestSelector",
    "signupReasonSelector",
  ];

  const noTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  const onStepChange: StepWizardProps["onStepChange"] = (stats) => {
    setCurrentStep(stats.activeStep);
  };

  const progressIndicator = (
    <>
      <ProgressIndicator
        steps={steps}
        currentStep={currentStep}
        className="mb-4"
      />
      <CDataTypography
        variant="typography-variant-headline-1"
        color="typography-color-dark-grey"
        className="mb-4 text-center"
      >
        Welcome to Connect Cloud
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-medium-grey"
        className="mb-4 text-center"
      >
        To personalize your onboarding experience, we just need a few details
        from you.
      </CDataTypography>
    </>
  );

  return (
    <>
      <SurveyWizardContext.Provider
        value={{
          surveyForm: surveyForm,
          setSurveyForm: setSurveyForm,
          existingSignupReasons: existingSignupReasons,
          setSignupReasonsAsync: setSignupReasonsAsync,
          isSetSignUpReasonsPending: isSetSignUpReasonsPending,
        }}
      >
        <StepWizard
          transitions={noTransitions}
          isLazyMount={true}
          initialStep={initialStep}
          onStepChange={onStepChange}
          isHashEnabled={false}
          nav={progressIndicator}
        >
          <AboutUser />
          <SelectDataSourcesAndTools />
          <SignupReasonSelector setSurveyCompleted={setSurveyCompleted} />
        </StepWizard>
      </SurveyWizardContext.Provider>
      <div hidden={!isSetSignUpReasonsPending}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
};
